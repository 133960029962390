// http://meyerweb.com/eric/tools/css/reset/
// v2.0 | 20110126

html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote,
a, code, img, strong,
b, u, i, center, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, form, input,
figure, figcaption, footer, header,
menu, nav, section, audio, video {
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  // line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &::before, &::after {
    content: '';
    content: none;
  }
}

q {
  &::before, &::after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
